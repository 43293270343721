import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import noPage from "assets/svg/404.svg"
import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"

const Styled404 = styled.div`
  background: ${({ theme }) => theme.gradient.linear.purple_to_green};
  padding: 11.5rem 0 7.5rem 0;
  text-align: center;
  color: ${({ theme }) => theme.color.text.inverted};
  min-height: 100vh;
  height: 100%;

  ${({ theme }) => theme.mq.s} {
    padding: 11.5rem 0 5.5rem 0;
    min-height: 100vh;
    height: 100%;
  }

  ${({ theme }) => theme.mq.xs} {
    padding: 11.5rem 0 4.5rem 0;
    min-height: 100vh;
    height: 100%;
  }

  @media screen and (orientation: landscape) and (max-height: 450px) {
    padding: 7.5rem 0 14.5rem 0;
  }
`

const StyledHeader = styled.h1`
  font-size: 4rem;
`

const StyledParagraph = styled.p`
  font-size: 2rem;
`

const StyledImg404 = styled(noPage)`
  max-width: 400px;
  width: 100%;
  max-height: 280px;
  height: 100%;
  margin: 2rem 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  box-shadow: ${({ theme }) => `0 0 20px ${theme.color.blue_700}`};
`

const NotFoundPage = ({ location }) => (
  <TransitionWrapper location={location}>
    <Styled404>
      <Seo title="404 not found" />
      <StyledHeader>404: Not Found</StyledHeader>
      <StyledImg404 />
      <StyledParagraph>You just hit a route that doesn&#39;t exist... the sadness.</StyledParagraph>
    </Styled404>
  </TransitionWrapper>
)

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default NotFoundPage
